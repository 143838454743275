import { ReactNode } from "react";

// Entire object returned from the API
export interface ClimateTableData {
  table_view: {
    columns: {
      legend: Array<keyof ClimateTableDataRow>;
      data_columns: string[];
    };
    rows: Array<ClimateTableDataRow>;
    trend_rows: Array<{
      title: string;
      data_columns: Array<DataColumnItem>;
    }>;
  };
  table_variables: ClimateTableDataVariables;
}

// Each row in the table
export interface ClimateTableDataRow {
  season: string;
  variable: string;
  indicator: string;
  data_columns: Array<DataColumnItem>;
  footnote_refs?: {
    season?: number;
    variable?: number;
    indicator?: number;
  };
}

interface DataColumnItem {
  value: string;
  footnote_refs?: number;
}

// Transformed data type for each row
export interface ClimateTableDataItem {
  season: string | ReactNode;
  variable: string | ReactNode;
  indicator: string | ReactNode;
  [key: string]: string | ReactNode;
}

export interface ClimateTableDataVariables {
  seasons: ClimateTableDataVariablesItem;
  climate_variables: ClimateTableDataVariablesItem;
  date_range: ClimateTableDataVariablesItem;
  data_types: ClimateTableDataVariablesItem;
  scenarios: ClimateTableDataVariablesItem;
  years: ClimateTableDataVariablesItem;
  historical_periods: ClimateTableDataVariablesItem;
  station: ClimateTableDataVariablesItem;
  table_type: ClimateTableDataVariablesItem;
  decimal_point: ClimateTableDataVariablesItem;
}

// Filter options
export interface ClimateTableDataVariablesItem {
  options: string[];
  defaults: string[] | string;
  multiple: boolean;
  checked: string[] | string;
}

// gemeten variablelen
type ClimateTableDataVariablesMapping = {
  [key: string]: string;
};

export const variableMapping: ClimateTableDataVariablesMapping = {
  temp: "Temperatuur (°C)",
  precip: "Neerslag (mm/dag)",
  radiation: "Straling (W/m2)",
  maxtemp: "Max. Temperatuur (°C)",
  mintemp: "Min. Temperatuur (°C)",
  relhumidity: "Relatieve vochtigheid (%)",
  windspeed: "Windsnelheid (m/s)",
};
